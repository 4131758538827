import React, { useRef, useState, useEffect } from "react";
import Header from "./Header";
import { SwipeableDrawer, IconButton } from "@material-ui/core";
import globalStyles from "../helpers/GlobalStyles";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import constant from "../constants/content.json";
import { Link, useHistory } from "react-router-dom";
import Footer from "./Footer";

const useStyles = makeStyles(
  (theme) => ({
    menu: {
      right: "0",
      alignSelf: "flex-start",
    },
    content: {
      paddingLeft: "9.5%",
      paddingRight: "9.5%",
      "& p": { color: "#fff" },
      "& a": { textDecoration: "none", color: "inherit" },
      "& h1,h2,h3,h4,h5,h6": { color: "#FDC300" },
      "&& li": { marginLeft: "-1.5%" },
      // "&& li p": { marginTop: "-27px" },
    },
    google: { display: "inline" },

    swipeableDrawer: {
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    },
    drawer: {
      width: "44vh",
      height: "100%",
      backgroundColor: "#171A1D",
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
      },
    },
    closeDrawer: {
      width: "5vh",
      height: "5vh",
      minWidth: "25px",
      minHeight: "25px",
      marginTop: "4vh",
      marginLeft: "2vh",
      "@media screen and (max-device-width: 500px)": {
        marginTop: "2vh",
        marginLeft: "2vh",
      },
    },
    closeDrawerIcon: {
      width: "4vh",
      height: "4vh",
      minWidth: "20px",
      minHeight: "20px",
      color: "#FDC300",
      "@media screen and (max-device-width: 500px)": {
        width: "3vh",
        height: "3vh",
      },
    },
    webSite: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      "&:hover": {
        color: "white",
      },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    webSiteDisabled: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      textDecorationColor: "#FDC300",
      // "&:hover": {
      //   color: "white",
      // },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    webSiteMediaQuery: {
      color: "#FDC300",
      "& a": {
        color: "#FDC300",
      },
      display: "none",
      "@media (max-width: 1024px)": {
        display: "inherit",
      },
    },
    drawerInput: {
      marginTop: "4vh",
      "& .MuiInput-underline:before": {
        borderBottom: "3px solid #000",
      },
      "& .MuiInput-underline:hover:before": {
        borderBottom: "3px solid #444",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "3px solid #736d6d",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "#171A1D",
        opacity: 1,
      },
    },
    drawerList: {
      margin: "3vh",
      height: "100%",
      "@media screen and (max-device-width: 800px)": {
        marginLeft: "6vh",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginLeft: "6vh",
        },
    },
    drawerListTitle: {
      fontSize: "25px",
      color: "#fff",
    },
  }),
  { index: 1 }
);
function PrivacyandPolicy(props) {
  const classes = useStyles();
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 800 ||
      (window.innerWidth <= 1024 && window.innerHeight > window.innerWidth)
  );
  const globalClasses = globalStyles();
  return (
    <>
      <Header
        setSwipeIndex={setSwipeIndex}
        swipeIndex={swipeIndex}
        setOpenDrawer={setOpenDrawer}
        props={props}
      />
      <div>
        <SwipeableDrawer
          anchor="right"
          open={openDrawer}
          className={classes.swipeableDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <div className={`${classes.drawer} ${globalClasses.flexColumn}`}>
            <IconButton
              className={classes.closeDrawer}
              onClick={() => setOpenDrawer(false)}
            >
              <CloseIcon className={classes.closeDrawerIcon} />
            </IconButton>
            {/* <TextField
                className={classes.drawerInput}
                placeholder="Search asaadshub"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            <div
              className={`${classes.drawerList} ${globalClasses.flexColumn}`}
            >
              <h2 className={classes.drawerListTitle}>Go to website</h2>
              {constant?.map((item, index) =>
                item.buttons.map((val, key) =>
                  val.status ? (
                    <a
                      href={val.url}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      className={`${classes.webSite}`}
                      onClick={(e) => {
                        if (isMobile) {
                          setSwipeIndex(1);
                        }
                        //setSelectedSite(item.value);
                        //setSelectedSite(item.buttons.url);
                        //console.log(item.buttons[0].url);
                        setOpenDrawer(false);
                      }}
                    >
                      {val.sidebarTitle}
                    </a>
                  ) : (
                    <span className={classes.webSiteDisabled}>
                      {val.sidebarTitle}
                    </span>
                  )
                )
              )}
              <Link
                to="/contact-us"
                style={{ color: "#FDC300" }}
                className={`${classes.webSite} ${classes.webSiteMediaQuery}`}
              >
                Contact us
              </Link>
              <div style={{ color: "#FDC300", marginTop: "80px" }}>
                ASAADSHUB INC.
              </div>
              <div style={{ color: "#FDC300" }}>Toronto, Canada</div>
              <div style={{ color: "#FDC300" }}>
                Email:{" "}
                <a href="mailto:info@asaadshub.com" style={{ color: "#FFFF" }}>
                  info@asaadshub.com
                </a>
                <br />
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
      <div className={classes.content}>
        <h1>Privacy Policy for AsaadsHub Inc.</h1>

        <p>
          At AsaadsHub, accessible from AsaadsHub.ca, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by AsaadsHub and
          how we use it.
        </p>

        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us. Our Privacy Policy was
          generated with the help of{" "}
          <a href="https://www.gdprprivacypolicy.net/">
            GDPR Privacy Policy Generator from GDPRPrivacyPolicy.net
          </a>
        </p>

        <h2>General Data Protection Regulation (GDPR)</h2>
        <p>We are a Data Controller of your information.</p>

        <p>
          AsaadsHub Inc. legal basis for collecting and using the personal
          information described in this Privacy Policy depends on the Personal
          Information we collect and the specific context in which we collect
          the information:
        </p>
        <ul>
          <li>AsaadsHub Inc. needs to perform a contract with you</li>
          <li>You have given AsaadsHub Inc. permission to do so</li>
          <li>
            Processing your personal information is in AsaadsHub Inc. legitimate
            interests
          </li>
          <li>AsaadsHub Inc. needs to comply with the law</li>
        </ul>

        <p>
          AsaadsHub Inc. will retain your personal information only for as long
          as is necessary for the purposes set out in this Privacy Policy. We
          will retain and use your information to the extent necessary to comply
          with our legal obligations, resolve disputes, and enforce our
          policies.
        </p>

        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.
        </p>

        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ul>
          <li>
            The right to access, update or to delete the information we have on
            you.
          </li>
          <li>The right of rectification.</li>
          <li>The right to object.</li>
          <li>The right of restriction.</li>
          <li>The right to data portability</li>
          <li>The right to withdraw consent</li>
        </ul>

        <h2>Log Files</h2>

        <p>
          AsaadsHub follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </p>

        <h2>Cookies and Web Beacons</h2>

        <p>
          Like any other website, AsaadsHub uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </p>

        <h2>Our Advertising Partners</h2>

        <p>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </p>

        <ul>
          <li>
            {/* {" "} */}
            Google
            <p style={{ marginTop: "10px" }}>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>

        <h2>Privacy Policies</h2>

        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of AsaadsHub.
        </p>

        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on AsaadsHub, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>

        <p>
          Note that AsaadsHub has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>

        <h2>Third Party Privacy Policies</h2>

        <p>
          AsaadsHub's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{" "}
        </p>

        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>

        <h2>Children's Information</h2>

        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p>
          AsaadsHub does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>

        <h2>Online Privacy Policy Only</h2>

        <p>
          Our Privacy Policy created at GDPRPrivacyPolicy.net) applies only to
          our online activities and is valid for visitors to our website with
          regards to the information that they shared and/or collect in
          AsaadsHub. This policy is not applicable to any information collected
          offline or via channels other than this website.
        </p>

        <h2>Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyandPolicy;
