import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g transform="translate(0 0)">
		<g transform="translate(34.613)">
			<path d="M-12.6,20h-2.1V6.6l2.1-2.6V20z"/>
		</g>
		<g transform="translate(0 0.48)">
			<path d="M14.5,19.5h-2.7L2,7.1l0-3.3L14.5,19.5z"/>
		</g>
		<g transform="translate(0 12.751)">
			<path d="M9.4,7.2H6.8L2,1.2v-3.3L9.4,7.2z"/>
		</g>
		<g transform="translate(0 25.075)">
			<path d="M4.3-5.1H2v-3L4.3-5.1z"/>
		</g>
		<g transform="translate(27.204 8.758)">
			<path d="M-9,11.2h-2.1V2.4L-9-0.2V11.2z"/>
		</g>
		<g transform="translate(20.91 17.931)">
			<path d="M-6.6-4.6v3.7l-1.5-1.9L-6.6-4.6z"/>
		</g>
	</g>,
    'Mercurial',
);