import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import intuitiveHubLogo from "../svgs/intuitiveHubLogo.svg";
import waveStudioLogo from "../svgs/waveStudioLogo.svg";
import quantumHubLogo from "../svgs/quantumHubLogo.svg";
import asaadsHubLogo from "../svgs/asaadsHubLogo.svg";
import whispers2ReflectionsLogo from "../svgs/whispers2ReflectionsLogo.svg";
import hinaBandiLogo from "../svgs/hinaBandiLogo.svg";
import quantumCloudLogo from "../svgs/quantumCloudLogo.svg";
import mercurialLogo from "../svgs/mercurialLogo.svg";
import asaadsRiazMusicLogo from "../svgs/asaadsRiazMusicLogo.svg";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import constant from "../constants/content.json";
import Header from "./Header";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import Footer from "./Footer";
import {
  IconButton,
  InputAdornment,
  SwipeableDrawer,
  TextField,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ContactForm from "../components/ContactForm";

const useStyles = makeStyles(
  (theme) => ({
    menu: {
      right: "0",
      alignSelf: "flex-start",
    },

    // drawer: {
    //   width: "40vh",
    //   height: "100%",
    //   backgroundColor: "#FDC300",
    //   "@media screen and (max-device-width: 500px)": {
    //     width: "100%",
    //   },
    // },

    topLineArt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
    },
    bottomLineArt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      flex: 1,
    },
    lineArtImg: {
      width: "20%",
      height: "auto",
    },
    menuIcon: {
      zIndex: 5,
    },
    menuIconLarge: {
      width: "7vh !important",
      height: "7vh !important",
    },
    hiddenMenu: {
      visibility: "hidden !important",
      opacity: "0 !important",
    },
    searchIcon: {
      marginLeft: "3vh",
    },
    something: {
      color: "#FDC300",
    },
    green: {
      color: "#00FF87",
    },
    contactsomething: {
      fontWeight: 600,
      [theme.breakpoints.down("xl")]: {
        textAlign: "left",
      },
      [theme.breakpoints.down("lg")]: {
        textAlign: "left",
      },
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      color: "#FDC300",
      textAlign: "center",
    },
    discussed: {
      fontWeight: 600,
      color: "white",
    },
    divider: {
      backgroundColor: "#FDC300",
      marginTop: 16,
      marginBottom: 16,
    },
    text: {
      color: "white",
    },
    contact: {
      // backgroundColor: "#212427",
      borderRadius: "5px",
      padding: 20,
      [theme.breakpoints.down("xl")]: {
        paddingLeft: "10%",
      },
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "10%",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "0",
        padding: 20,
      },
    },
    contactAddresses: {
      // backgroundColor: "#212427",
      // paddingTop: "4%",
      // paddingBottom: "8%",
      // paddingRight: "3%",
      // paddingLeft: "3%",
    },
    gaph: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: "7%",
      paddingLeft: "10%",
    },
    // footer: {
    //   width: "100%",
    //   backgroundColor: "#FDC300",
    //   color: "black",
    //   padding: "8px",
    //   [theme.breakpoints.down("md")]: {
    //     display: "none",
    //   },
    // },
    marginTop: {
      /*marginTop: "2rem"*/
      [theme.breakpoints.only("xl")]: {
        marginTop: "2rem",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "2rem",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "2rem",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "0",
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: "0",
      },
    },
    paddingLeft: {
      /*paddingLeft: "5%"*/
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "8%",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "8%",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "5%",
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: "0",
      },
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "0",
      },
    },
    contactItems: {
      marginLeft: "15%",
      [theme.breakpoints.only("md")]: {
        paddingLeft: "20%",
        paddingRight: "20%",
        marginLeft: "0",
      },
      [theme.breakpoints.only("sm")]: {
        // paddingLeft: "15%",
        paddingRight: "15%",
        marginLeft: "0",
      },
      [theme.breakpoints.only("xs")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
        marginLeft: "0",
      },
    },
    contactItemsNoMargin: { marginLeft: "0" },

    contactContainer: {
      // paddingBottom:"20px",
      // height: "80vh",
      [theme.breakpoints.only("xl")]: {
        marginTop: "6rem",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "5rem",
      },
      // [theme.breakpoints.only("md")]: {
      //   marginTop: "4rem",
      // },
      // [theme.breakpoints.only("sm")]: {
      //   marginTop: "3rem",
      // },
      [theme.breakpoints.only("xs")]: {
        marginTop: "0",
      },
    },
    swipeableDrawer: {
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    },
    drawer: {
      width: "44vh",
      height: "100%",
      backgroundColor: "#171A1D",
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
      },
    },
    closeDrawer: {
      width: "5vh",
      height: "5vh",
      minWidth: "25px",
      minHeight: "25px",
      marginTop: "4vh",
      marginLeft: "2vh",
      "@media screen and (max-device-width: 500px)": {
        marginTop: "2vh",
        marginLeft: "2vh",
      },
    },
    closeDrawerIcon: {
      width: "4vh",
      height: "4vh",
      minWidth: "20px",
      minHeight: "20px",
      color: "#FDC300",
      "@media screen and (max-device-width: 500px)": {
        width: "3vh",
        height: "3vh",
      },
    },
    drawerInput: {
      marginTop: "4vh",
      "& .MuiInput-underline:before": {
        borderBottom: "3px solid #000",
      },
      "& .MuiInput-underline:hover:before": {
        borderBottom: "3px solid #444",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "3px solid #736d6d",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "#171A1D",
        opacity: 1,
      },
    },
    drawerList: {
      margin: "3vh",
      height: "100%",
      "@media screen and (max-device-width: 800px)": {
        marginLeft: "6vh",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginLeft: "6vh",
        },
    },
    drawerListTitle: {
      fontSize: "25px",
      color: "#fff",
    },

    addressList: {
      // paddingLeft: 0,
      // marginTop: "7%",
      //marginLeft: "-1rem",
      [theme.breakpoints.down("xl")]: {
        paddingLeft: 35,
        // marginLeft: "7.5%",
      },
      [theme.breakpoints.down("lg")]: {
        paddingLeft: 33,
        // marginLeft: "6.5%",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
        marginLeft: "0",
      },
    },
    webSite: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      "&:hover": {
        color: "white",
      },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    webSiteDisabled: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      textDecorationColor: "#FDC300",
      // "&:hover": {
      //   color: "white",
      // },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    drawerContact: {
      color: "#12161D",
      flex: 1,
      display: "flex",
      alignItems: "flex-end",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "20px",
        color: "#FFFFFF",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "20px",
          color: "#FFFFFF",
        },
    },
    webSiteMediaQuery: {
      color: "#FDC300",
      "& a": {
        color: "#FDC300",
      },
      display: "none",
      "@media (max-width: 1024px)": {
        display: "inherit",
      },
    },
    footerSection: {
      backgroundColor: "#171A1D",
      // position: "-webkit-sticky",
      position: "sticky",
      "@media screen and (min-device-height:799px)":
      {
        position: "absolute",    
      },
      bottom: "0",
    },
    footer: {
      width: "100%",
      backgroundColor: "#212427",
      // backgroundColor: "#FDC300",
      color: "#FDC300",
      padding: "8px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    policyLink: {
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        color: "white",
      },
    },
    terms: { marginLeft: "2rem" },
    footerDiv: { paddingLeft: "9.5%" },
    policyDiv: {
      display: "flex",
      justifyContent: "right",
      paddingRight: "9.5%",
    },
    ah: { display: "inline" },
  }),
  { index: 1 }
);

function ContactUs(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 800 ||
      (window.innerWidth <= 1024 && window.innerHeight > window.innerWidth)
  );
  const [swipeIndex, setSwipeIndex] = useState(0);
  let myRef = useRef();

  const handleAbout = () => {
    // window.scrollTo({behavior: 'smooth', top: myRef.current.offsetTop})
  };
  const handleWebsiteSelect = (value) => {
    history.push("/home", { update: value });
  };

  useEffect(() => {
    // document.getElementsByName("title").content="Your description about the page or site here to set dynamically";
    setTitle("Contact us");
  }, []);
  const setTitle = (title) => {
    const el = document.querySelector("title");
    // el.innerText = `${el.text} | ${title}`;
    el.innerText = `${title} - Asaadshub`;
  };

  return (
    <>
      <Header
        setSwipeIndex={setSwipeIndex}
        swipeIndex={swipeIndex}
        setOpenDrawer={setOpenDrawer}
        handleAbout={handleAbout}
        props={props}
      />

      {/* <br />
      <br />
      <br />
      <br />
      <br /> */}
      {/* <br />
      <br /> */}
      <div>
        <SwipeableDrawer
          anchor="right"
          open={openDrawer}
          className={classes.swipeableDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <div className={`${classes.drawer} ${globalClasses.flexColumn}`}>
            <IconButton
              className={classes.closeDrawer}
              onClick={() => setOpenDrawer(false)}
            >
              <CloseIcon className={classes.closeDrawerIcon} />
            </IconButton>
            {/* <TextField
                className={classes.drawerInput}
                placeholder="Search asaadshub"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            <div
              className={`${classes.drawerList} ${globalClasses.flexColumn}`}
            >
              <h2 className={classes.drawerListTitle}>Go to website</h2>
              {constant?.map((item, index) =>
                item.buttons.map((val, key) =>
                  val.status ? (
                    <a
                      href={val.url}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                      className={`${classes.webSite}`}
                      onClick={(e) => {
                        if (isMobile) {
                          setSwipeIndex(1);
                        }
                        //setSelectedSite(item.value);
                        //setSelectedSite(item.buttons.url);
                        //console.log(item.buttons[0].url);
                        setOpenDrawer(false);
                      }}
                    >
                      {val.sidebarTitle}
                    </a>
                  ) : (
                    <span className={classes.webSiteDisabled}>
                      {val.sidebarTitle}
                    </span>
                  )
                )
              )}
              <Link
                to="/contact-us"
                style={{ color: "#FDC300" }}
                className={`${classes.webSite} ${classes.webSiteMediaQuery}`}
              >
                Contact us
              </Link>
              <div style={{ color: "#FDC300", marginTop: "80px" }}>
                ASAADSHUB INC.
              </div>
              <div style={{ color: "#FDC300" }}>Toronto, Canada</div>
              <div style={{ color: "#FDC300" }}>
                Email:{" "}
                <a href="mailto:info@asaadshub.com" style={{ color: "#FFFF" }}>
                  info@asaadshub.com
                </a>
                <br />
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
      <Grid
        container
        direction={"row"}
        justify={"center"}
        className={classes.contactContainer}
      >
        <Grid item lg={6} md={12} sm={12} xs={12} className={classes.contact}>
          <Typography variant={"h3"} className={classes.contactsomething}>
            <span className={classes.discussed}>Our</span> Offices
          </Typography>

          <ul className={classes.addressList}>
            <Grid container className={classes.contactAddresses}>
              <Grid item md={6} sm={6} xs={12}>
                <li
                  className={clsx(
                    classes.contactItems,
                    classes.contactItemsNoMargin
                  )}
                >
                  {/* {" "} */}
                  {/* <p className={classes.ah}> */}
                  ASAADSHUB
                  <span className={classes.something}>
                    {" "}
                    <b>INC.</b>
                  </span>
                  {/* </p> */}
                  <br />
                  3250 Bloor Street West East Tower Suite 600 Toronto Ontario
                  M8X 2X9 Canada
                  {/* 3250 Bloor Street West
                      <br />
                      East Tower Suite 600 Toronto
                      <br />
                      Ontario M8X 2X9
                      <br />
                      Canada */}
                  <br />
                  Email:
                  <span>
                    {" "}
                    <a
                      className={classes.something}
                      href="mailto:info@asaadshub.com"
                    >
                      {" "}
                      info@asaadshub.com
                    </a>
                  </span>
                  <br />
                  Contact:
                  <span>
                    <a className={classes.something} href="tel:+4372415018">
                      {" "}
                      +1 437-241-5018
                    </a>
                  </span>
                </li>

                {/* <Divider classes={{ root: classes.divider }} /> */}
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <li className={classes.contactItems}>
                  {/* <p className={classes.ah}> */}
                  ASAADSHUB
                  <span className={classes.something}>
                    {" "}
                    <b>INC.</b>
                  </span>
                  {/* </p> */}
                  <br />
                  Saudi Arabia, UAE and Qatar (MENA)
                  <br />
                  Email:
                  <span>
                    {" "}
                    <a
                      className={classes.something}
                      href="mailto:mena@asaadshub.com"
                    >
                      {" "}
                      mena@asaadshub.com
                    </a>
                  </span>
                  <br />
                  Contact:
                  <span>
                    <a className={classes.something} href="tel:+966055299078">
                      {" "}
                      +966 (0) 55 299 2078
                    </a>
                  </span>
                </li>
              </Grid>
              <Grid item md={6} sm={6} xs={12} className={classes.marginTop}>
                <li
                  className={clsx(
                    classes.contactItems,
                    classes.contactItemsNoMargin
                  )}
                >
                  {/* <p className={classes.ah}> */}
                  ASAADSHUB
                  <span className={classes.something}>
                    {" "}
                    <b>INC.</b>
                  </span>
                  {/* </p> */}
                  <br />
                  Queen Creek,
                  <br />
                  AZ (Arizona), USA
                  <br />
                  Email:{" "}
                  <a
                    href="mailto:info@asaadshub.com"
                    // style={{ color: "#FFFF" }}
                    className={classes.something}
                  >
                    info@asaadshub.com
                  </a>
                  <br />
                  Contact:
                  <span>
                    {" "}
                    <a className={classes.something} href="tel:909-583-5315">
                      {" "}
                      +1 909-583-5315
                    </a>
                  </span>
                </li>
                {/* <Divider classes={{ root: classes.divider }} /> */}
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                /*className={classes.paddingLeft}*/
                className={classes.marginTop}
              >
                <li className={classes.contactItems}>
                  {/* <p className={classes.ah}>*/
                  /*</p> */}
                  ASAADSHUB
                  <br />
                  Gulshan-e-Iqbal, Karachi,
                  <br />
                  Pakistan
                  <br />
                  Email:
                  <span>
                    {" "}
                    <a
                      className={classes.something}
                      href="mailto:info@asaadshub.com"
                    >
                      {" "}
                      info@asaadshub.com
                    </a>
                  </span>
                  <br />
                  Contact:
                  <span>
                    {" "}
                    <a className={classes.something} href="tel:909-583-5315">
                      {" "}
                      +1 909-583-5315
                    </a>
                  </span>
                </li>
                {/* <Divider classes={{ root: classes.divider }} /> */}
              </Grid>
            </Grid>
          </ul>
        </Grid>
        {/* <Grid item  lg={6} md={12} sm={12} xs={12}>
          <Grid container direction={"row"} justify={"center"}> */}
        <Grid item lg={6} md={12} sm={12} xs={12} className={classes.gaph}>
          {/* <Typography variant={"h3"} className={classes.something}>
                Need something
              </Typography>
              <Typography variant={"h3"} className={classes.discussed}>
                Discussed?
              </Typography>

              <Divider classes={{ root: classes.divider }} /> */}

          <Typography
            style={{ fontWeight: "600" }}
            variant={"h3"}
            className={classes.something}
          >
            Get in <span className={classes.discussed}>touch</span>.
          </Typography>
          <br />
          <Typography
            // style={{ marginTop: "1%" }}
            variant={"body1"}
            className={classes.text}
          >
            Our highly capable representatives here at{" "}
            <span className={classes.something}>asaadshub</span>
          </Typography>
          <Typography
            // style={{ marginBottom: "1%" }}
            variant={"body1"}
            className={classes.text}
          >
            would very much take care of any queries you have.
          </Typography>
          <ContactForm />
        </Grid>

        {/* <Grid item lg={2} md={12} sm={12} xs={12}></Grid> */}
      </Grid>
      {/* </Grid>
      </Grid> */}
      {/* <Footer styles={{ postion: "absolute" }} /> */}
      
      <Grid
      container
      direction={"row"}
      alignItems={"center"}
      justify={"center"}
      className={classes.footerSection}
    >
      {/* <Grid item lg={11} sm={12} ref={myRef} className={classes.aboutUs}>
      <Typography variant={"h3"} className={classes.footerTitle}>
        <b style={{ fontWeight: "500" }}>About</b> asaads
        <span style={{ color: "white" }}>hub</span>
      </Typography>

      <Typography variant={"body1"} className={classes.footerDescription}>
        AsaadsHub is the parent holding company to all AsaadsHub nodes and
        sub-nodes. From incubation, capital investments, startups to
        sourcing all enterprise solution.
      </Typography>
    </Grid> */}

      {/* <Hidden only={["sm", "xs"]}>
                <img src={FooterBanner} className={classes.footerBanner}/>
            </Hidden> */}
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        className={classes.footer}
      >
        <Grid item lg={6} sm={12} className={classes.footerDiv}>
          asaadshub © 2021
        </Grid>
        <Grid item lg={6} sm={12} className={classes.policyDiv}>
          <a
            href="/privacy-policy"
            className={classes.policyLink}

            // onClick={(e) => {
            //   console.log("link clicked");
            //   setDisplayPolicy(true);
            // }}
          >
            Privacy policy
          </a>
          <a
            href="/terms-and-conditions"
            className={clsx(classes.policyLink, classes.terms)}

            // onClick={(e) => {
            //   console.log("link clicked");
            //   setDisplayPolicy(true);
            // }}
          >
            Terms and conditions
          </a>
        </Grid>
        {/* <Grid item lg={4} sm={12}></Grid> */}
      </Grid>
    </Grid>
    </>
  );
}

export default ContactUs;
