import * as React from "react";
import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  <g>
    <rect x="2" y="7" fill="#FDC300" width="20" height="1.9" />
    <rect x="2" y="11.8" fill="#FDC300" width="20" height="1.9" />
    <rect x="2" y="16.5" fill="#FDC300" width="20" height="1.9" />
  </g>,
  "Menu"
);
