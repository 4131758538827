import React, { useEffect, useState } from "react";
import { Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "../icons/Menu";
import mobileLogo from "../svgs/mobileLogo.svg";
import clsx from "clsx";
import HomeIcon from "../icons/Home";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import globalClasses from "../helpers/GlobalStyles";
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(
  (theme) => ({
    footerSection: {
      backgroundColor: "#171A1D",
      // position: "-webkit-sticky",
      position: "sticky",
      bottom: "0",
    },
    footer: {
      width: "100%",
      backgroundColor: "#212427",
      // backgroundColor: "#FDC300",
      color: "#FDC300",
      padding: "8px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    policyLink: {
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        color: "white",
      },
    },
    terms: { marginLeft: "2rem" },
    footerDiv: { paddingLeft: "9.5%" },
    policyDiv: {
      display: "flex",
      justifyContent: "right",
      paddingRight: "9.5%",
    },
  }),
  { index: 1 }
);

function Footer({isContact}) {
  const classes = useStyles();
  const[home,setHome]=useState(false)
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname)
    if(location.pathname==="/")setHome(true)
  },[])
  // console.log(props);

  return (
    <Grid
      container
      direction={"row"}
      alignItems={"center"}
      justify={"center"}
      className={classes.footerSection}
      style={home ? {position:"absolute"} : null}
    >
      {/* <Grid item lg={11} sm={12} ref={myRef} className={classes.aboutUs}>
      <Typography variant={"h3"} className={classes.footerTitle}>
        <b style={{ fontWeight: "500" }}>About</b> asaads
        <span style={{ color: "white" }}>hub</span>
      </Typography>

      <Typography variant={"body1"} className={classes.footerDescription}>
        AsaadsHub is the parent holding company to all AsaadsHub nodes and
        sub-nodes. From incubation, capital investments, startups to
        sourcing all enterprise solution.
      </Typography>
    </Grid> */}

      {/* <Hidden only={["sm", "xs"]}>
                <img src={FooterBanner} className={classes.footerBanner}/>
            </Hidden> */}
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        className={classes.footer}
      >
        <Grid item lg={6} sm={12} className={classes.footerDiv}>
          asaadshub © 2021
        </Grid>
        <Grid item lg={6} sm={12} className={classes.policyDiv}>
          <a
            href="/privacy-policy"
            className={classes.policyLink}

            // onClick={(e) => {
            //   console.log("link clicked");
            //   setDisplayPolicy(true);
            // }}
          >
            Privacy policy
          </a>
          <a
            href="/terms-and-conditions"
            className={clsx(classes.policyLink, classes.terms)}

            // onClick={(e) => {
            //   console.log("link clicked");
            //   setDisplayPolicy(true);
            // }}
          >
            Terms and conditions
          </a>
        </Grid>
        {/* <Grid item lg={4} sm={12}></Grid> */}
      </Grid>
    </Grid>
  );
}

export default Footer;
