import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Home from "./pages/Home";
import TermsandConditions from "./pages/TermsandConditions";
import PrivacyandPolicy from "./pages/PrivacyandPolicy";
import ContactUs from "./pages/ContactUs";
import CookieConsent from "react-cookie-consent";
import CookiePopup from "./components/CookiePopup/Index";

import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1400,
  },
}));

function App() {
  const classes = useStyles();
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00FF87",
      },
      secondary: {
        main: "#ffffff",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 15,
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
  });

  return (
    <SnackbarProvider
      classes={{
        root: classes.root,
      }}
      dense={true}
      maxSnack={5}
      autoHideDuration={1000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <React.Fragment>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {/* <CookieConsent debug={true}></CookieConsent> */}
            <CookiePopup />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyandPolicy}
              />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsandConditions}
              />
            </Switch>
          </ThemeProvider>
        </BrowserRouter>
      </React.Fragment>
    </SnackbarProvider>
  );
}
export default App;
