import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g transform="translate(-94.751 -4458.047)">
		<g transform="translate(94.751 4458.047)">
			<g transform="translate(47.504 6.527)">
				<circle className="st0" cx="-29.2" cy="-1.8" r="1"/>
			</g>
			<g transform="translate(14.336 0)">
				<path d="M-2.6,9.1c-0.7,0.7-1.9,0.7-2.6,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1C-6.1,8.1-6,6.8-5.2,6l2-2
				C-2.4,3.2-3.5,2-4.4,2.7c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1c0.7-0.7,1.9-0.7,2.6,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1
				c0.7,0.9,0.7,2.2-0.1,3l-2,2C-4.5,8.4-3.4,9.7-2.5,9c0,0,0,0,0,0C-2.5,9-2.5,9.1-2.6,9.1z"/>
			</g>
			<g transform="translate(0 61.245)">
				<circle cx="5.7" cy="-42" r="1"/>
			</g>
			<g transform="translate(22.777 46.458)">
				<path d="M-10.5-31.6c0.7-0.7,1.9-0.7,2.6,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0.7,0.9,0.7,2.2-0.1,3
				l-2,2c-0.8,0.8,0.3,2.1,1.2,1.4c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1c-0.7,0.7-1.9,0.7-2.6,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
				c-0.7-0.9-0.7-2.2,0.1-3l2-2c0.8-0.8-0.3-2.1-1.2-1.4c0,0,0,0,0,0C-10.6-31.5-10.6-31.5-10.5-31.6z"/>
			</g>
			<g transform="translate(9.052 13.727)">
				<path d="M1.3,4.1c-0.7,0.7-1.9,0.7-2.6,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c-0.7-0.9-0.7-2.2,0.1-3
				l7-7c0.8-0.8-0.3-2.1-1.2-1.4l0,0c0.6-0.8,1.8-0.9,2.6-0.3c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1s0,0,0.1,0.1
				c0.7,0.9,0.7,2.2-0.1,3l-7,7C-0.6,3.4,0.5,4.7,1.4,4c0,0,0,0,0,0C1.4,4,1.3,4,1.3,4.1z"/>
			</g>
		</g>
	</g>,
    'IntuitiveHub',
);