import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g transform="translate(0)">
		<path className="st0" d="M15.7,18v-6.1H8.4v8.7h7.3v-1h0.7V20h0.5v1.6c0,0.2-0.2,0.4-0.4,0.4H7.5c-0.2,0-0.4-0.2-0.4-0.4
		V11c0-0.2,0.2-0.4,0.4-0.4h8.9c0.2,0,0.4,0.2,0.4,0.4v6.7h-0.5V18L15.7,18z"/>
		<path className="st0" d="M12,2L12,2C5.9,2,6.4,8.8,6.4,8.8l0.8-0.1l0.5-0.1l0.3,0l0,0v0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0-0.1c0,0,0,0,0,0v0c0,0,0,0,0,0C8,8.1,8,7.8,8,7.5c0,0,0,0,0,0c0,0,0,0,0,0C8.1,6.6,8.4,5.8,8.9,5C9,4.9,9.1,4.8,9.2,4.7
		l0,0c0.1-0.1,0.2-0.2,0.3-0.3C9.7,4.3,9.8,4.2,10,4.1c0.3-0.1,0.5-0.3,0.8-0.3c0.8-0.2,1.7-0.2,2.5,0c0.3,0.1,0.5,0.2,0.8,0.3
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0,0C14.9,4.8,15,4.9,15.1,5c0.5,0.7,0.8,1.6,0.9,2.4c0,0,0,0,0,0c0,0,0,0,0,0
		C16,7.8,16,8.1,16,8.4c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0v0l0,0l0.3,0l0.5,0.1l0.8,0.1
		C17.6,8.8,18.1,2,12,2z"/>
	</g>,
    'AsaadsHub',
);