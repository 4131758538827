import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import axios from "axios";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { array, number, object, string } from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import NewCaseField from "./ContactField";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "26rem",
    height: "2.8rem",
    marginTop: "4%",
    borderRadius: "9px",
    border: "1px solid #FDC300",
    backgroundColor: "#212427",
    paddingLeft: "3%",
    color: "white",
    "&::placeholder": { fontFamily: ["Montserrat-Medium", "Montserrat"] },
    [theme.breakpoints.down("xl")]: {
      marginTop: "4%",
      width: "25.5rem",
      height: "2.6rem",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "4%",
      width: "25.6rem",
      height: "2.4rem",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
      width: "24rem",
      height: "2.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "4%",
      width: "80%",
      height: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "4%",
      width: "80%",
      height: "2rem",
    },
  },
  firstInput: {
    marginTop: "6%",
    [theme.breakpoints.down("xl")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "8%",
    },
  },
  submit: {
    height: "2.6rem",
    width: "6rem",
    borderRadius: "9px",
    border: "1px solid #FDC300",
    backgroundColor: "#212427",
    color: "#FDC300",
    fontWeight: "600",
    marginTop: "6%",
    fontSize: "1rem",

    [theme.breakpoints.down("xl")]: {
      marginTop: "4%",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "4%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "3%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "6%",
    },
  },
  progress: {
    display: "inline",
    position: "absolute",
    marginTop: "3vh",
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5vh",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "9vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "5vh",
    },
  },
  message: { height: "7rem", paddingTop: "2%" },
  // form: { marginTop: "5rem" },
  error: {
    color: "#EA3635",
    position: "absolute",
    fontSize: "0.7rem",
  },
}));

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

function ContactForm(props) {
  const classes = useStyles();

  const [emailSent, setEmailSent] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    // event.preventDefault();
    // setIsSubmitting(true);

    if (event.name || event.email || event.subject || event.message) {
      console.log(event.name);
      console.log(event.email);
      console.log(event.subject);
      console.log(event.message);

      // https://m1bctewrn4.execute-api.us-east-1.amazonaws.com/send
      axios
        .post(`https://sdvb2k8onf.execute-api.us-west-1.amazonaws.com/prod`, {
          body: {
            name: event.name,
            email: event.email,
            subject: event.subject,
            message: event.message,
          },
        })
        .then((res) => {
          // console.log(res);
          // console.log(res.data);
          enqueueSnackbar("Email sent successfully", { variant: "success" });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Something went wrong. Try again later.", {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Missing Values", {
        variant: "error",
      });
    }
    // setIsSubmitting(false);
  };

  const validateSchema = object().shape({
    name: string().required("Name is required"),
    email: string().email().required("Email address is required"),
    subject: string().required("Subject  is required"),
    message: string().required("Message is required"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialState}
      validationSchema={validateSchema}
      validateOnBlur={false}
      validateOnChange
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      render={({
        isSubmitting,
        dirty,
        touched,
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <input
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.name && touched.name && "error"}
                  type="text"
                  name="name"
                  placeholder="Company name"
                  className={clsx(classes.input, classes.firstInput)}
                />
                {errors.name && touched.name && (
                  <div className={classes.error}>{errors.name}</div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  className={clsx(classes.input, classes.firstInput)}
                />
                {errors.email && touched.email && (
                  <div className={classes.error}>{errors.email}</div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <input
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.subject && touched.subject && "error"}
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  className={clsx(classes.input, classes.firstInput)}
                />
                {errors.subject && touched.subject && (
                  <div className={classes.error}>{errors.subject}</div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <textarea
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.message && touched.message && "error"}
                  type="text"
                  name="message"
                  placeholder="Description of service"
                  className={clsx(
                    classes.input,
                    classes.firstInput,
                    classes.message
                  )}
                  // className={clsx(classes.input, classes.message)}
                />
                {errors.message && touched.message && (
                  <div className={classes.error}>{errors.message}</div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                type={"submit"}
                value="Submit"
                onClick={(event) => handleSubmit(event)}
                className={classes.submit}
                disabled={isSubmitting}
              >
                Submit
              </Button>

              {isSubmitting ? (
                <Box className={classes.progress}>
                  <CircularProgress sx={{ color: "#FDC300" }} />
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
}

export default ContactForm;
