import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g >
		<path d="M9.6,15.2H4.5l-0.9,2.6H2L6.2,6.2H8l4.2,11.6h-1.6L9.6,15.2z M9.2,14L7.1,8.1L5,14H9.2z"/>
		<path d="M20.2,17.8l-2.8-4.8h-1.8v4.8H14V6.2h3.8c0.9,0,1.6,0.2,2.2,0.5c0.6,0.3,1.1,0.7,1.4,1.2
		c0.3,0.5,0.5,1.1,0.5,1.8c0,0.8-0.2,1.5-0.7,2.1c-0.5,0.6-1.2,1-2.1,1.2l2.9,4.9H20.2z M15.6,11.8h2.2c0.8,0,1.4-0.2,1.9-0.6
		c0.4-0.4,0.6-1,0.6-1.6c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.4-1-0.6-1.9-0.6h-2.2V11.8z"/>
	</g>,
    'AsaadRiazMusic',
);