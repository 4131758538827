import * as React from "react";
import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.51 533.42">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <path
            class="e"
            d="M144.56,233.11c-26.62,0-48.13-22.29-46.87-49.18,1.13-23.87,20.77-43.5,44.64-44.61,26.88-1.25,49.15,20.26,49.15,46.87,0,9.5-2.86,18.33-7.75,25.72-2.34,3.54-2.47,8.1-.35,11.78h0c2.23,3.86,7.7,4.33,10.44,.82,10.03-12.83,15.17-29.64,12.54-47.66-4.09-27.97-27.21-49.98-55.33-52.83-38.39-3.89-70.73,27.23-68.89,65.24,1.49,30.81,25.94,56.38,56.66,59.14,10.42,.94,20.38-.71,29.31-4.36,3.48-1.42,4.81-5.69,2.93-8.95h0c-2.38-4.12-7.32-5.84-11.83-4.35-4.61,1.52-9.54,2.35-14.65,2.35Z"
          />
          <path
            class="d"
            d="M151.45,91.7h0c0,3.86,2.85,7.08,6.67,7.64,44.56,6.57,78.31,46.82,74.43,93.98-3.46,42.05-37.35,76.15-79.38,79.85-46.52,4.09-86.43-28.46-94.1-71.98-.73-4.12-4.22-7.17-8.4-7.17h0c-4.42,0-7.87,3.94-7.18,8.3,8.35,52.13,55.89,91.35,111.37,86.32,49.28-4.46,88.92-44.3,93.17-93.6,4.81-55.76-35.06-103.41-87.73-111.13-4.68-.68-8.85,3.05-8.85,7.78Z"
          />
          <path
            class="d"
            d="M134.07,91.87h0c0-4.51-4.09-8.01-8.52-7.15-41.05,7.99-73.47,40.4-81.48,81.45-1.06,5.42,3.06,10.48,8.58,10.48h0c3.1,0,5.77-2.21,6.28-5.27,5.96-35.44,33.29-63.71,68.24-71.09,4.01-.85,6.9-4.33,6.9-8.42Z"
          />
          <path
            class="d"
            d="M158.74,40.84c-42.9-3.65-82.38,11.4-111.25,37.86-3.23,2.96-3.32,8.03-.22,11.13h0c2.97,2.97,7.74,3.07,10.85,.24,25.76-23.55,60.95-36.95,99.19-33.72,63.29,5.34,114.1,56.67,118.85,120.01,5.73,76.45-54.85,140.49-130.11,140.49-51.49,0-96.09-29.99-117.3-73.41-1.44-2.95-4.87-4.35-8-3.39h0c-5.01,1.54-7.53,7.22-5.17,11.9,24.07,47.68,73.51,80.47,130.47,80.47,84.23,0,152.04-71.68,145.63-157.25-5.31-70.86-62.13-128.3-132.94-134.32Z"
          />
          <path
            class="d"
            d="M15.57,186.35c0-27.22,8.39-52.51,22.7-73.44,2.14-3.13,1.71-7.34-.97-10.02h0c-3.37-3.37-9.01-2.91-11.7,1.02C9.47,127.39,0,155.78,0,186.35c0,11.07,1.26,21.85,3.61,32.22,1.11,4.9,6.23,7.77,11.04,6.3h0c3.37-1.04,5.4-4.5,4.57-7.93-2.38-9.81-3.65-20.05-3.65-30.58Z"
          />
          <path
            class="d"
            d="M27.67,313.99c-3.31-3.02-8.62-2.53-11.35,1.26-2.39,3.31-1.61,8,1.42,10.73,19.53,17.68,42.77,31.3,68.38,39.64,4.23,1.38,8.75-1.12,9.83-5.43h0c1.01-4.01-1.24-8.14-5.17-9.42-23.66-7.72-45.12-20.37-63.11-36.78Z"
          />
          <path
            class="d"
            d="M180.86,.12c-3.81-.74-7.42,1.98-7.67,5.85l-.1,1.5c-.25,3.92,2.4,7.46,6.25,8.24,83.56,17.09,145.47,94.37,138.19,184.41-6.73,83.23-73.08,150.55-156.21,158.43-14.94,1.42-29.53,.91-43.58-1.28-3.38-.53-6.59,1.66-7.43,4.98l-.19,.78c-1.15,4.57,1.89,9.15,6.55,9.84,16.69,2.48,34.09,2.77,51.89,.56,85.65-10.64,153.75-79.24,163.78-164.97C344.25,106.78,274.91,18.5,180.86,.12Z"
          />
          <path
            class="d"
            d="M344.06,75.7h0c-3.64,1.89-5.11,6.37-3.24,10.02,15.18,29.59,23.85,63.05,24.03,98.49,.61,119.29-96.21,218.25-215.49,220.18-43.22,.7-83.65-11.23-117.9-32.28-3.04-1.87-6.96-1.3-9.27,1.41l-.45,.54c-3.05,3.6-2.14,9.06,1.88,11.52,38.34,23.48,83.89,36.28,132.51,34.18,119.52-5.18,216.94-101.09,223.88-220.53,2.52-43.3-6.82-84.26-25.01-119.98-2.04-4.01-6.94-5.62-10.94-3.55Z"
          />
          <path
            class="d"
            d="M147.04,450.93c-31.94,0-62.73-6.86-91.13-17.23-3.93-1.43-8.27,.61-9.76,4.52l-.08,.2c-1.55,4.06,.57,8.55,4.65,10.04,30.02,10.92,62.4,16.88,96.14,16.88s63.24-5.46,92.13-15.47c4.41-1.53,6.5-6.56,4.43-10.74l-.07-.14c-1.74-3.51-5.81-5.11-9.51-3.83-27.27,9.45-56.35,15.77-86.79,15.77Z"
          />
          <path
            class="d"
            d="M258.49,433.93l.02,.04c1.88,3.79,6.46,5.45,10.28,3.61,51.5-24.8,94.36-64.8,122.71-114.15,2.12-3.69,.77-8.41-2.91-10.54h0c-3.7-2.14-8.47-.92-10.6,2.78-26.8,46.63-67.3,84.39-115.97,107.82-3.88,1.87-5.43,6.58-3.52,10.44Z"
          />
          <path
            class="d"
            d="M151.93,489.92c-8.72,0-17.35-.38-25.88-1.11-4.24-.36-8.01,2.65-8.48,6.88h0c-.48,4.38,2.75,8.26,7.13,8.63,8.98,.77,18.06,1.17,27.23,1.17,87.35,0,166.63-35.18,224.46-92.1,2.97-2.92,3.02-7.7,.2-10.76h0c-2.96-3.21-8-3.39-11.11-.33-55.01,54.15-130.44,87.61-213.54,87.61Z"
          />
          <path
            class="d"
            d="M313.55,496.74h0c-2.2-3.34-6.55-4.43-10.11-2.61-20.03,10.24-41.34,18.32-63.62,23.94-4.05,1.02-6.62,4.97-5.82,9.06h0c.87,4.42,5.26,7.14,9.62,6.04,23.43-5.92,45.85-14.42,66.92-25.2,4.14-2.12,5.56-7.35,3.01-11.24Z"
          />
        </g>
      </g>
    </g>
  </svg>,
  "WaveStudio"
);
