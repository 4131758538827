import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g id="Group_1518" transform="translate(0 0)">
		<path d="M20.3,7.5c0,0-0.3,3-2.4,4.6s-3.2,2-3.2,2s3.1-0.3,5.1-2.8S22,7.5,22,7.5L20.3,7.5z"/>
		<path d="M17.5,6.1c0,0-3.4,5.8-6.2,5.8S8.7,14.1,8,14.4s-4.3,0.9-5,1.9c-0.4,0.5-0.7,1-1,1.6
		c0.9-0.6,1.9-1,3-1.1c1.9-0.1,1.7,0,3,0s1.6-0.9,1.9-2.1s1,0.7,2.9-1S17.5,6.1,17.5,6.1z"/>
		<path d="M9.7,7.6c0,0-1.7,1.9-2.1,2.2s0,0.7,0,0.7c0.5-0.1,0.9-0.2,1.3-0.4c0.4-0.2,3-3.1,3-3.1
		S10.5,7.6,9.7,7.6"/>
	</g>,
    'HinaBandi',
);