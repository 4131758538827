import React from 'react'
import {makeStyles} from '@material-ui/core'
import { Field,ErrorMessage} from "formik";
import FormError from "./FormError";


import FMUInputField from "./FMUInputField";
import { Typography } from "@material-ui/core";

const useStyles= makeStyles((theme) => ({
    parentChild:{
      height:"50px",
        flex: "1",
        width:"100%",
        "&:nth-child(1)": {
          paddingRight:"5vh"
        },
        "&:nth-child(2)": {
          paddingLeft:"5vh"
        },
      },
      rowContainer: {
        display: "flex",
        marginTop: "8px",
      },
      labelContainer: {
        display: "flex",
        width: "50%",
        alignItems: "center",
        "& p":{
          fontSize:"1em",
          fontWeight:"bold",
          color:"black"
        }
      },
      fieldContainer: {
        width: "50%",
      },
      input: {
        width: "95%",
        border: "1px solid #9dc8d2",
        "& .MuiInputBase-input":{
          textIndent:"10px",
          textTransform:"capitalize"
        },
        borderRadius: "10px",
        height:"40px",
        paddingLeft: "3px",
        backgroundColor:"#f8f8f8",
        
      },
      emailInput: {
        width: "95%",
        border: "1px solid #9dc8d2",
        "& .MuiInputBase-input":{
          textIndent:"10px",
        },
        borderRadius: "10px",
        height:"40px",
        paddingLeft: "3px",
        backgroundColor:"#f8f8f8",
        
      },
      error: {
        fontSize: "12px",
        color: "red",
        marginTop: "0.25rem",
        paddingLeft:"50%"
      },
}))
function NewCaseField({label,name,type,disabled,placeHolder,isEmail,required}) {
    const classes=useStyles()
    return (
        <div className={classes.parentChild}>
                  <div className={classes.rowContainer}>
                    <div className={classes.labelContainer} >
                      {
                        required ? <Typography>{label}<span style={{color:"red"}}>*</span></Typography> : <Typography>{label}</Typography>
                      }
                      
                    </div>
                    <div className={classes.fieldContainer}>
                      <Field
                        
                        name={name}
                        type={type}
                        disabled={disabled}
                        fullwidth={true}
                        
                        disableUnderline={true}
                        className={isEmail ? classes.emailInput : classes.input}
                        component={FMUInputField}
                        label={placeHolder}
                      />
                      
                    </div>
                  </div>
                  <div>
                  <ErrorMessage
                  name={name}
                  component={FormError}
                  className={classes.error}
                />
                  </div>

                </div>
    )
}

export default NewCaseField
