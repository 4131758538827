import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<path d="M22,16.8c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.7c-0.8-1.7-1.9-2.1-4.6-2.1
	c0.1-0.2,0.1-0.3,0.1-0.5v0c0-0.8-0.1-1.5-0.1-2.3c0-0.1,0-0.8,0.1-0.9c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0l0,0
	c0.1-0.3,0.1-0.4,0.1-0.6c0.1-0.4,0.1-0.8,0.1-1.2c0-0.1,0-0.3,0-0.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.3-0.2-0.5-0.3-0.8-0.5
	c-0.2-0.1-0.5-0.4-0.5-0.4c0.4-0.9,0.2-1.5-0.2-1.8l0,0c-0.1-0.1-0.3-0.2-0.4-0.2l0,0c-0.2-0.1-0.4-0.1-0.6,0c0,0,0,0,0,0
	c-0.1,0-0.2,0.1-0.3,0.1h0c-0.3,0.2-0.5,0.5-0.5,0.9l0,0c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.1,0,0.2,0,0.2
	c0,0.3,0,0.5,0.1,0.8l0,0C13.8,6.9,13.4,7,13.3,7c0,0,0,0-0.1,0c-0.2,0.2-0.4,0.4-0.5,0.7l0.1,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.4,0-0.6c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.1,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0.1-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1-0.1-0.1-0.1c-0.1-0.4-0.3-0.5-0.5-0.4
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2C11.1,4.8,11,4.9,11,5c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
	c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2,0.2-0.1,0.3c0,0.2,0.2,0.3,0.2,0.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1
	c0.1,0.3,0,0.5-0.3,0.7c-0.6,0.3-1.7,0.1-1.7,1.1c0,0.5,0.2,1.2,0.4,2c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3
	c0,0.1,0.1,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0-1.2,1.3-0.6,2.3c-4.7-0.3-6.5-1.9-6.9-2.3c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1
	c3,4.7,11.8,3,11.9,3c0.1,0,0.2,0.1,0.1,0.2c-3.9,1.6-8.5,0-8.6-0.1c2.1,2.3,8.7,0.9,8.8,0.9s0.3,0.2,0.1,0.3
	c-4,1.7-6.6,0.8-6.7,0.8c3.3,1.7,7.5-0.1,7.6-0.1c0.2,0,0.3,0.2,0.1,0.4c-3.5,1.8-5.7,1.1-5.7,1.1c1,0.9,4.3,0.9,6.2-0.2
	c0,0,0,0,0.1,0c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0,0,0,0c0.7-0.3,1.5-0.5,2.3-0.4c0.9,0.1,1.5,0.4,1.9,1c0.3,0.4,0.4,0.9,0.4,1.4
	c0,0,0,0,0,0C21.8,18.9,22.1,17.8,22,16.8z M13.4,12.4C13.4,12.4,13.4,12.4,13.4,12.4C13.4,12.4,13.4,12.4,13.4,12.4L13.4,12.4z"/>,
    'Whispers2Reflections',
);