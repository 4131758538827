import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g>
		<path fill="#FDC300" d="M6.9,10.6h10.1c1,0,1.8,0.8,1.8,1.8v7.4c0,1-0.8,1.8-1.8,1.8H6.9c-1,0-1.8-0.8-1.8-1.8v-7.4
	C5.1,11.5,5.9,10.6,6.9,10.6z"/>
		<path fill="#FDC300" d="M11.4,2.9c0.3-0.3,0.9-0.3,1.2,0l9.1,8c0.4,0.3,0.4,0.9,0.1,1.3c-0.2,0.2-0.4,0.3-0.7,0.3H2.9
	c-0.5,0-0.9-0.4-0.9-0.9c0-0.3,0.1-0.5,0.3-0.7L11.4,2.9z"/>
	</g>,
	'Home',
);