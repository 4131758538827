import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  IconButton,
  InputAdornment,
  RadioGroup,
  SwipeableDrawer,
  TextField,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import globalStyles from "../helpers/GlobalStyles";
import CustomRadio from "../components/CustomRadio";
import dividerLine from "../svgs/dividerLine.svg";
import intuitiveHubLogo from "../svgs/intuitiveHubLogo.svg";
import intuitiveHubLogoNew from "../svgs/intutivehub_250x250.svg";
import waveStudioLogo from "../svgs/newwavelogo.svg";
import waveStudioLogoNew from "../svgs/wavestudio_250x250.svg";
import quantumHubLogo from "../svgs/QuantumhubSvgLogo.svg";
import quantumHubLogoNew from "../svgs/quantumhub_250x250.svg";
import asaadsHubLogo from "../svgs/asaadsHubLogo.svg";
import asaadsHubLogoNew from "../svgs/asaadshub_250x250.svg";
import whispers2ReflectionsLogo from "../svgs/whispers2ReflectionsLogo.svg";
import whispers2ReflectionsLogoNew from "../svgs/w2r_250x250.svg";
import hinaBandiLogo from "../svgs/hinaBandiLogo.svg";
import hinaBandiLogoNew from "../svgs/hinabandi_250x250.svg";
import quantumCloudLogo from "../svgs/quantumCloudLogo.svg";
import quantumCloudLogoNew from "../svgs/quantumcloud_250x250.svg";
import mercurialLogo from "../svgs/mercurialLogo.svg";
import mercurialLogoNew from "../svgs/mercurial_250x250.svg";
import asaadsRiazMusicLogo from "../svgs/asaadsRiazMusicLogo.svg";
import ArrowRight from "../icons/ArrowRight";
import AsaadsHubIcon from "../icons/AsaadsHub";
import AsaadRiazMusicIcon from "../icons/AsaadRiazMusic";
import HinaBandiIcon from "../icons/HinaBandi";
import IntuitiveHubIcon from "../icons/IntuitiveHub";
import MercurialIcon from "../icons/Mercurial";
import QuantumCloudIcon from "../icons/QuantumCloud";
import QuantumHubIcon from "../icons/QuantumHub";
import WaveStudioIcon from "../icons/WaveStudio";
import Whispers2ReflectionsIcon from "../icons/Whispers2Reflections";
import constant from "../constants/content.json";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import FooterBanner from "../icons/FooterBanner.png";
import queryString from "query-string";
import Header from "./Header";
import { Link, useLocation, useHistory } from "react-router-dom";
import PrivacyandPolicy from "./PrivacyandPolicy";
import Footer from "./Footer";

const useStyles = makeStyles(
  (theme) => ({
    leftSide: {
      height: "100%",
      alignItems: "center",
      width: "50%",
      "@media screen and (max-device-width: 800px)": {
        width: "100%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          width: "100%",
        },
    },
    dividerLine: {
      width: "2px",
      height: "45%",
      marginBottom: "10%",
      "@media screen and (max-device-width: 800px)": {
        display: "none !important",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "none !important",
        },
    },
    dividerLineHorizontal: {
      width: "75%",
      height: "2px",
      marginBottom: "10px",
      backgroundColor: "#FDC300",
      "@media screen and (max-device-width: 800px)": {
        display: "none !important",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "none !important",
        },
    },
    rightSide: {
      height: "100%",
      marginRight: "16vh",
      flex: 1,
      "@media screen and (max-device-width: 800px)": {
        display: "none !important",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "none !important",
        },
    },
    mobileContent: {
      background: "#171A1D",
      display: "none",
      height: "calc(var(--vh, 1vh) * 80)",
      "@media screen and (max-device-width: 800px)": {
        display: "flex",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "flex",
        },
    },
    logo: {
      height: "10vh",
      marginLeft: "20vh",
      marginTop: "4vh",
      alignSelf: "flex-start",
      display: "unset",
      "@media screen and (max-device-width: 1000px)": {
        marginLeft: "6vh",
      },
      "@media screen and (max-device-width: 900px)": {
        marginLeft: "2vh",
      },
      "@media screen and (max-device-width: 800px)": {
        display: "none",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "none",
        },
    },
    menu: {
      position: "absolute",
      right: "0",
      marginTop: "4vh",
      marginRight: "20vh",
      alignSelf: "flex-start",
      "@media screen and (max-device-width: 1000px)": {
        marginRight: "6vh",
      },
      "@media screen and (max-device-width: 900px)": {
        marginRight: "2vh",
      },
      "@media screen and (max-device-width: 800px)": {
        display: "none !important",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "none !important",
        },
    },
    mobileHeader: {
      width: "100%",
      position: "absolute",
      background: "#171A1D",
      paddingTop: "2vh",
      justifyContent: "space-between",
      left: "0",
      top: "0",
      zIndex: 10,
      display: "none !important",
      "@media screen and (max-device-width: 800px)": {
        display: "flex !important",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "flex !important",
        },
    },
    mobileMenu: {
      marginLeft: "2vh",
    },
    mobileHome: {
      marginRight: "2vh",
    },
    mobileHomeHide: {
      opacity: 0,
    },
    mobileLogo: {
      height: "7vh",
    },
    drawerIcon: {
      width: "5vh",
      height: "5vh",
    },
    swipeableDrawer: {
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    },
    drawer: {
      width: "44vh",
      height: "100%",
      // backgroundColor: "#FDC300",
      backgroundColor: "#171A1D",
      "@media screen and (max-device-width: 500px)": {
        width: "100%",
      },
    },
    closeDrawer: {
      width: "5vh",
      height: "5vh",
      minWidth: "25px",
      minHeight: "25px",
      marginTop: "4vh",
      marginLeft: "2vh",
      "@media screen and (max-device-width: 500px)": {
        marginTop: "2vh",
        marginLeft: "2vh",
      },
    },
    closeDrawerIcon: {
      width: "4vh",
      height: "4vh",
      minWidth: "20px",
      minHeight: "20px",
      color: "#FDC300",
      "@media screen and (max-device-width: 500px)": {
        width: "3vh",
        height: "3vh",
      },
    },
    topLineArt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flex: 1,
    },
    bottomLineArt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      flex: 1,
    },
    lineArtImg: {
      width: "20%",
      height: "auto",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "70%",
      // marginTop: "13vh",
      overflowY: "auto",
      "@media screen and (max-device-width: 800px)": {
        height: "100%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          height: "100%",
        },
    },
    contentLogo: {
      width: "35%",
      padding: "3vh",
      height: "25vh",
      "@media screen and (max-device-width: 800px)": {
        width: "60vmin",
        // flex: 1,
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          width: "60vmin",
          // flex: 1,
        },
    },
    contentLogoIh: { width: "45%", height: "27vh" },
    // contentLogoQh: {
    //   paddingBottom: 0,
    // },
    contentLogoWS: {
      paddingBottom: "5vh",
      // "@media screen and (max-device-width: 800px)": {
      //   marginBottom: "5vh",
      //   // flex: 1,
      // },
    },
    desc: {
      textAlign: "center",
      marginRight: "13vh",
      marginLeft: "13vh",
      fontSize: "15px",
      "@media screen and (max-device-width: 800px)": {
        margin: "3vh",
        marginTop: 0,
        display: "flex",
        alignItems: "center",
        // flex: 1,
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          margin: "3vh 14vh",
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          // flex: 1,
        },
    },
    gotoContent: {
      fontSize: "20px",
      color: "#FFFFFF",
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "2vh",
      marginBottom: "10vh",
      "@media screen and (max-device-width: 800px)": {
        marginBottom: "5vh",
        fontSize: "16px",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginBottom: "5vh",
          fontSize: "16px",
        },
    },
    gotoWebsite: {
      display: "flex",
      alignItems: "flex-end",
      cursor: "pointer",
      marginRight: "10px",
      marginLeft: "10px",
      whiteSpace: "nowrap",
      "& > *:last-child": {
        marginLeft: "1vh",
      },
      "@media screen and (max-device-width: 800px)": {
        alignItems: "unset",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          alignItems: "unset",
        },
    },
    gotoWebsiteHover: {
      color: "white",
      "&:hover": {
        color: "#FDC300",
      },
    },
    radioGroup: {
      width: "100%",
      alignItems: "center",
      // marginTop: "7vh",
      justifyContent: "center",
      flex: 1,
      "@media screen and (max-device-width: 800px)": {
        // marginTop: "9vh",
        // marginTop: "-12vh",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginTop: "10vh",
        },
    },
    hexagonRoot: {
      width: "max-content",
      alignItems: "center",
      marginLeft: "6vh",
      justifyContent: "center",
      "@media screen and (max-device-width: 800px)": {
        marginLeft: 0,
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginLeft: 0,
        },
    },
    hexagonLastRoot: {
      alignSelf: "flex-start",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "21vh",
      marginTop: "5vh",
      "@media screen and (max-device-width: 800px)": {
        alignSelf: "unset",
        marginLeft: 0,
        marginTop: 0,
      },
    },
    mobileHexagon: {
      display: "none",
      "@media screen and (max-device-width: 800px)": {
        display: "unset",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          display: "unset",
        },
    },
    menuIcon: {
      zIndex: 5,
    },
    menuIconLarge: {
      width: "7vh !important",
      height: "7vh !important",
    },
    hiddenMenu: {
      visibility: "hidden !important",
      opacity: "0 !important",
    },
    searchIcon: {
      marginLeft: "3vh",
    },
    drawerInput: {
      marginTop: "4vh",
      "& .MuiInput-underline:before": {
        borderBottom: "3px solid #000",
      },
      "& .MuiInput-underline:hover:before": {
        borderBottom: "3px solid #444",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "3px solid #736d6d",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "#171A1D",
        opacity: 1,
      },
    },
    drawerList: {
      margin: "3vh",
      height: "100%",
      "@media screen and (max-device-width: 800px)": {
        marginLeft: "6vh",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          marginLeft: "6vh",
        },
    },
    drawerListTitle: {
      fontSize: "25px",
      color: "#fff",
    },
    webSite: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      textDecorationColor: "#FDC300",
      "&:hover": {
        color: "white",
      },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    webSiteDisabled: {
      width: "90%",
      display: "block",
      fontSize: "18px",
      color: "#FDC300",
      textDecorationColor: "#FDC300",
      // "&:hover": {
      //   color: "white",
      // },
      marginBottom: "2vh",
      borderBottom: "2px solid #FFFFFF",
      cursor: "pointer",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "16px",
        width: "60%",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "16px",
          width: "60%",
        },
    },
    drawerContact: {
      color: "#12161D",
      flex: 1,
      display: "flex",
      alignItems: "flex-end",
      "@media screen and (max-device-width: 800px)": {
        fontSize: "20px",
        color: "#FFFFFF",
      },
      "@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)":
        {
          fontSize: "20px",
          color: "#FFFFFF",
        },
    },
    menuItem: {
      color: "#FDC300",
      marginLeft: 12,
      marginRight: 12,
    },
    menuLabel: {
      color: "#FDC300",
    },
    aboutUsButton: {
      right: 0,
      position: "absolute",
      alignSelf: "flex-start",
      marginTop: "6vh",
      marginRight: "30vh",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    contactButton: {
      right: 0,
      position: "absolute",
      alignSelf: "flex-start",
      marginTop: "6vh",
      marginRight: "44vh",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    homeButton: {
      right: 0,
      position: "absolute",
      alignSelf: "flex-start",
      marginTop: "6vh",
      marginRight: "58vh",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    aboutUs: {
      textAlign: "center",
    },
    footerTitle: {
      color: "#FDC300",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    footerDescription: {
      color: "white",
      margin: "0px auto",
      marginBottom: "160px !important",
      width: "60%",
      // textAlign: 'justify',
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    footer: {
      width: "100%",
      backgroundColor: "#212427",
      // backgroundColor: "#FDC300",
      color: "#FDC300",
      padding: "8px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    divider: {
      width: 2,
      backgroundColor: "#FDC300",
    },
    footerSection: {
      backgroundColor: "#171A1D",
      position: "-webkit-sticky",
      position: "sticky",
      bottom: "0",
    },
    footerBanner: {
      height: "200px",
      width: "100%",
    },
    header: {
      height: "70px",
      width: "100%",
    },
    homeContainer: { height: "20rem" },
    webSiteMediaQuery: {
      display: "none",
      "@media (max-width: 1024px)": {
        display: "inherit",
      },
    },
    menuIconWave: {
      width: "6vh !important",
      height: "6vh !important",
    },
    policyLink: { textDecoration: "none", color: "inherit" },
    terms: { marginLeft: "1rem" },
    footerDiv: { paddingLeft: "9.5%" },
  }),
  { index: 1 }
);

const logos = [
  asaadsHubLogo,
  quantumHubLogoNew,
  intuitiveHubLogo,
  waveStudioLogoNew,
  whispers2ReflectionsLogoNew,
  quantumCloudLogo,
  hinaBandiLogoNew,
  asaadsRiazMusicLogo,
  mercurialLogoNew,
];

function Home(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayPolicy, setDisplayPolicy] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const myparam = location?.state?.update;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 800 ||
      (window.innerWidth <= 1024 && window.innerHeight > window.innerWidth)
  );
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [selectedSite, setSelectedSite] = useState(
    myparam
      ? myparam
      : window.innerWidth < 800 ||
        (window.innerWidth <= 1024 && window.innerHeight > window.innerWidth)
      ? 10
      : 0
  );

  let myRef = useRef();
  let params = queryString.parse(props.location.search);

  useEffect(() => {
    if (params.search) {
      window.scrollTo({ behavior: "smooth", top: myRef.current.offsetTop });
    }
  }, []);
  useEffect(() => {
    if (myparam) {
      if (isMobile) {
        setSwipeIndex(1);
      }
      setSelectedSite(myparam);
    }
    history.replace();
  }, []);

  const handleResize = () => {
    setSwipeIndex(0);
    if (
      window.innerWidth < 800 ||
      (window.innerWidth <= 1024 && window.innerHeight > window.innerWidth)
    ) {
      setSelectedSite(10);
      setIsMobile(true);
    } else {
      setSelectedSite(0);
      setIsMobile(false);
    }
  };

  const handleAbout = () => {
    window.scrollTo({ behavior: "smooth", top: myRef.current.offsetTop });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    // document.getElementsByName("title").content="Your description about the page or site here to set dynamically";
    setTitle();
  }, []);
  const setTitle = (title) => {
    const el = document.querySelector("title");
    // el.innerText = `${el.text} | ${title}`;
    el.innerText = `Asaadshub`;
  };

  return (
    <>
      <Header
        setSwipeIndex={setSwipeIndex}
        swipeIndex={swipeIndex}
        setOpenDrawer={setOpenDrawer}
        handleAbout={handleAbout}
        props={props}
      />
      {/*<div className={`${classes.mobileHeader} ${globalClasses.flexRow}`}>
                <IconButton
                    className={classes.mobileMenu}
                    onClick={() => setOpenDrawer(true)}
                >
                    <MenuIcon/>
                </IconButton>
                <img className={classes.mobileLogo} src={mobileLogo} alt="logo"/>

                <IconButton
                    className={swipeIndex === 0 ? clsx(classes.mobileHomeHide, classes.mobileHome) : classes.mobileHome}
                    disabled={swipeIndex === 0}
                    onClick={() => {
                        // setSelectedSite(10);
                        setSwipeIndex(0);
                    }}
                >
                    <HomeIcon/>
                </IconButton>
            </div>*/}

      <SwipeableViews
        axis="y"
        enableMouseEvents={false}
        onTransitionEnd={() => {
          if (swipeIndex === 0) setSelectedSite(10);
        }}
        disabled={true}
        animateHeight={true}
        index={isMobile ? swipeIndex : 0}
      >
        <div className={`${globalClasses.root} ${globalClasses.flexRow}`}>
          <SwipeableDrawer
            anchor="right"
            open={openDrawer}
            className={classes.swipeableDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
          >
            <div className={`${classes.drawer} ${globalClasses.flexColumn}`}>
              <IconButton
                className={classes.closeDrawer}
                onClick={() => setOpenDrawer(false)}
              >
                <CloseIcon className={classes.closeDrawerIcon} />
              </IconButton>
              {/* <TextField
                className={classes.drawerInput}
                placeholder="Search asaadshub"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              /> */}
              <div
                className={`${classes.drawerList} ${globalClasses.flexColumn}`}
              >
                <h2 className={classes.drawerListTitle}>Go to website</h2>
                {constant?.map((item, index) =>
                  item.buttons.map((val, key) =>
                    val.status ? (
                      <a
                        href={val.url}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                        className={`${classes.webSite}`}
                        onClick={(e) => {
                          if (isMobile) {
                            setSwipeIndex(1);
                          }
                          //setSelectedSite(item.value);
                          //setSelectedSite(item.buttons.url);
                          //console.log(item.buttons[0].url);
                          setOpenDrawer(false);
                        }}
                      >
                        {val.sidebarTitle}
                      </a>
                    ) : (
                      <span className={classes.webSiteDisabled}>
                        {val.sidebarTitle}
                      </span>
                    )
                  )
                )}
                <Link
                  to="/contact-us"
                  style={{ color: "#FDC300" }}
                  className={`${classes.webSite} ${classes.webSiteMediaQuery}`}
                >
                  Contact us
                </Link>
                <div style={{ color: "#FDC300", marginTop: "80px" }}>
                  ASAADSHUB INC.
                </div>
                <div style={{ color: "#FDC300" }}>Toronto, Canada</div>
                <div style={{ color: "#FDC300" }}>
                  Email:{" "}
                  <a
                    href="mailto:info@asaadshub.com"
                    style={{ color: "#FFFF" }}
                  >
                    info@asaadshub.com
                  </a>
                  <br />
                </div>
              </div>
            </div>
          </SwipeableDrawer>

          <div className={`${classes.leftSide} ${globalClasses.flexColumn}`}>
            {/*<div className={classes.header}>
                            <img className={classes.logo} src={logo} alt="logo"/>
                        </div>*/}
            <RadioGroup
              className={classes.radioGroup}
              value={selectedSite}
              onChange={(e) => {
                if (isMobile) {
                  setSwipeIndex(1);
                }
                setSelectedSite(parseInt(e.target.value));
              }}
            >
              <div
                className={`${classes.hexagonRoot} ${globalClasses.flexRow}`}
              >
                <div className={globalClasses.flexColumn}>
                  <CustomRadio
                    icon={
                      <IntuitiveHubIcon
                        className={clsx(
                          classes.menuIconLarge,
                          classes.menuIcon
                        )}
                      />
                    }
                    value={2}
                  />
                  <CustomRadio
                    wave={true}
                    icon={
                      <WaveStudioIcon
                        className={clsx(classes.menuIconWave, classes.menuIcon)}
                      />
                    }
                    value={3}
                  />
                </div>
                <div className={globalClasses.flexColumn}>
                  <CustomRadio
                    icon={<QuantumHubIcon className={classes.menuIcon} />}
                    value={1}
                  />
                  <CustomRadio
                    icon={
                      <AsaadsHubIcon
                        className={clsx(
                          classes.menuIconLarge,
                          classes.menuIcon
                        )}
                      />
                    }
                    value={0}
                  />
                  <CustomRadio
                    icon={
                      <Whispers2ReflectionsIcon className={classes.menuIcon} />
                    }
                    value={4}
                  />
                </div>
                <div className={globalClasses.flexColumn}>
                  <CustomRadio
                    icon={<HinaBandiIcon className={classes.menuIcon} />}
                    value={6}
                  />
                  <CustomRadio
                    icon={<QuantumCloudIcon className={classes.menuIcon} />}
                    value={5}
                  />
                </div>
              </div>
              <div
                className={`${classes.hexagonLastRoot} ${globalClasses.flexRow}`}
              >
                <div className={globalClasses.flexColumn}>
                  <CustomRadio
                    lastRadio={true}
                    icon={<MercurialIcon className={classes.menuIcon} />}
                    value={8}
                  />
                </div>
                <div className={globalClasses.flexColumn}>
                  <CustomRadio
                    lastRadio={true}
                    icon={<AsaadRiazMusicIcon className={classes.menuIcon} />}
                    value={7}
                  />
                  <CustomRadio
                    lastRadio={true}
                    className={classes.hiddenMenu}
                    disabled={true}
                    value={9}
                  />
                </div>
                <div className={classes.mobileHexagon}>
                  <CustomRadio
                    lastRadio={true}
                    className={classes.hiddenMenu}
                    disabled={true}
                    value={10}
                  />
                </div>
              </div>
            </RadioGroup>
          </div>
          <img
            className={classes.dividerLine}
            src={dividerLine}
            alt="divider"
          />
          <div className={`${classes.rightSide} ${globalClasses.flexColumn}`}>
            {/* <div className={classes.topLineArt}>
                            <img className={classes.lineArtImg} src={lineArt} alt="art"/>
                        </div> */}
            <div className={`${classes.content} ${globalClasses.flexColumn}`}>
              <img
                className={
                  selectedSite === 1
                    ? clsx(classes.contentLogo, classes.contentLogoQh)
                    : selectedSite === 3
                    ? clsx(classes.contentLogo, classes.contentLogoWS)
                    : selectedSite === 2
                    ? clsx(classes.contentLogo, classes.contentLogoIh)
                    : classes.contentLogo
                }
                //className={classes.contentLogo}
                src={logos[selectedSite]}
                alt={constant[selectedSite]?.title}
              />
              <span
                style={
                  selectedSite === 1
                    ? {
                        marginTop: "20px",
                        color: constant[selectedSite]?.color,
                      }
                    : { color: constant[selectedSite]?.color }
                }
                className={classes.desc}
              >
                {constant[selectedSite]?.description}
              </span>
            </div>
            <div className={`${classes.gotoContent} ${globalClasses.flexRow}`}>
              {constant[selectedSite]?.buttons?.map((item, index) => (
                <>
                  {constant[selectedSite]?.status ? (
                    <a
                      key={index}
                      href={item.url}
                      style={selectedSite === 0 ? { display: "none" } : null}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.gotoWebsite}
                      disabled
                    >
                      <span className={classes.gotoWebsiteHover}>
                        {item.text}
                      </span>
                      <ArrowRight />
                    </a>
                  ) : (
                    <span className={classes.gotoWebsite}>Coming soon</span>
                  )}
                </>
              ))}
            </div>
            {/* <div className={classes.bottomLineArt}>
                            <img className={classes.lineArtImg} src={lineArt} alt="art"/>
                        </div> */}
            {/*<div className={` ${globalClasses.rootAbout} ${globalClasses.flexColumn}`}>
                            <img className={classes.dividerLineHorizontal} src={dividerLine} alt="divider"/>
                            <div style={{color: constant[selectedSite]?.color}}>
                                ASAADSHUB INC.
                            </div>
                            <div style={{color: constant[selectedSite]?.color}}>
                                30 N Gould St Ste R, Sheridan WY 82801, United States
                            </div>
                            <div style={{color: constant[selectedSite]?.color}}>
                                E: <span style={{color: "#FFFF"}}>INFO@ASAADSHUB.COM</span>
                            </div>

                        </div>*/}
          </div>
        </div>
        <div className={`${classes.mobileContent} ${globalClasses.flexColumn}`}>
          <div className={`${classes.content} ${globalClasses.flexColumn}`}>
            <img
              className={
                selectedSite === 1
                  ? clsx(classes.contentLogo, classes.contentLogoQh)
                  : selectedSite === 3
                  ? clsx(classes.contentLogo, classes.contentLogoWS)
                  : classes.contentLogo
              }
              src={logos[selectedSite]}
              alt={constant[selectedSite]?.title}
            />
            <span
              style={{ color: constant[selectedSite]?.color }}
              className={classes.desc}
            >
              {constant[selectedSite]?.description}
            </span>
            <div className={`${classes.gotoContent} ${globalClasses.flexRow}`}>
              {constant[selectedSite]?.buttons?.map((item, index) => (
                <>
                  {constant[selectedSite]?.status ? (
                    <a
                      key={index}
                      href={item.url}
                      style={selectedSite === 0 ? { display: "none" } : null}
                      target="_blank"
                      rel="noreferrer"
                      className={`${classes.gotoWebsite} `}
                      disabled
                    >
                      <span className={classes.gotoWebsiteHover}>
                        {item.text}
                      </span>
                      <ArrowRight />
                    </a>
                  ) : (
                    <span className={classes.gotoWebsite}>Coming soon</span>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </SwipeableViews>
      {/*footer*/}
      <Footer />
      {/* <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        className={classes.footerSection}
      >
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"center"}
          className={classes.footer}
        >
          <Grid item lg={6} sm={12} ref={myRef} className={classes.footerDiv}>
            asaadshub © 2021
          </Grid>
          <Grid item lg={6} sm={12}>
            <a
              href="/privacy-and-policy"
              className={classes.policyLink}

              // onClick={(e) => {
              //   console.log("link clicked");
              //   setDisplayPolicy(true);
              // }}
            >
              Privacy and policy
            </a>
            <a
              href="/terms-and-conditions"
              className={clsx(classes.policyLink, classes.terms)}

              // onClick={(e) => {
              //   console.log("link clicked");
              //   setDisplayPolicy(true);
              // }}
            >
              Terms and conditions
            </a>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
}

export default Home;
