import React, { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
function Index() {
    const [state,setState]=useState(true)
    useEffect(() => {
        sleep(3000).then(() => {
            // Do something after the sleep!
        setState(localStorage.getItem("asaadshub_cookie_policy") ? localStorage.getItem("asaadshub_cookie_policy")  :false)

        });
    },[])
    console.log(state)
    function sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }

    
    return (
    <div class="cookies-box" style={!state ? {left:"20px"} : null}>
        <span class="close-cookie" onClick={(e) => {setState(true)}}><CloseIcon/></span>
        <div class="cookies-inner">
        <h2>We use <br/>cookies</h2>
         <p>
                We use <strong>cookies</strong> to make <br/> your experience on this<br/> website better.
            </p>
            <div class="cookie-footer">
                <span style={{color:"#000"}}  class="accept-cookie cookie-btn" onClick={(e)=>{setState(true);localStorage.setItem("asaadshub_cookie_policy",true)}}>
                    Accept
                </span>
                <span href="/privacy-policy/" class="view-policy cookie-btn">Cookie Policies</span>
            </div>
        </div>
    </div>
    )
}

export default Index
