import React from "react";
import { Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "../icons/Menu";
import mobileLogo from "../svgs/mobileLogo.svg";
import clsx from "clsx";
import HomeIcon from "../icons/Home";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import globalClasses from "../helpers/GlobalStyles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(
  () => ({
    mobileHeader: {
      width: "100%",
      // position: "sticky",
      background: "#171A1D",
      paddingTop: "2vh",
      justifyContent: "space-between",
      // left: "0",
      // top: "0",
      zIndex: 10,
      display: "flex",
    },
    deskTopHeader: {
      width: "100%",
      // position: "sticky",
      background: "#171A1D",
      paddingTop: "2vh",
      justifyContent: "space-between",
      // left: "0",
      // top: "0",
      zIndex: 10,
      display: "flex",
    },
    mobileMenu: {
      marginLeft: "2vh",
    },
    mobileLogo: {
      height: "7vh",
    },
    mobileHomeHide: {
      opacity: 0,
    },
    mobileHome: {
      marginRight: "2vh",
    },
    logo: {
      height: "8vh",
      alignSelf: "flex-start",
    },
    menuItem: {
      color: "#FDC300",
      marginLeft: 12,
      marginRight: 12,
    },
    menuLabel: {
      color: "#FDC300",
      "&:hover": {
        color: "#fff",
      },
    },
    drawerIcon: {
      width: "5vh",
      height: "5vh",
    },

    menuIconHover: {
      "&:hover": {
        fill: "#fff",
      },
    },

    header: {
      position: "sticky",
      top: "0px",
      zIndex: 1000,
      height: "100px",
    },
  }),
  { index: 1 }
);

function Header({
  swipeIndex,
  setSwipeIndex,
  setOpenDrawer,
  handleAbout,
  props,
}) {
  const classes = useStyles();

  // console.log(props);

  return (
    <Grid
      container
      direction={"row"}
      justify={"center"}
      className={classes.header}
    >
      <Grid item lg={10} sm={12} xs={12}>
        <Hidden only={["lg", "xl"]}>
          <div className={`${classes.mobileHeader} ${globalClasses.flexRow}`}>
            <IconButton
              className={classes.mobileMenu}
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon
                className={classes.menuIconHover}
                style={{
                  "&:hover": {
                    fill: "#fff",
                  },
                }}
              />
            </IconButton>
            <Link to="/home">
              <img className={classes.mobileLogo} src={mobileLogo} alt="logo" />
            </Link>
            <IconButton
              className={
                swipeIndex === 0
                  ? clsx(classes.mobileHomeHide, classes.mobileHome)
                  : classes.mobileHome
              }
              disabled={swipeIndex === 0}
              onClick={() => {
                // setSelectedSite(10);
                setSwipeIndex(0);
              }}
            >
              <HomeIcon />
            </IconButton>
          </div>
        </Hidden>

        <Hidden only={["md", "sm", "xs"]}>
          <div className={`${classes.deskTopHeader} ${globalClasses.flexRow}`}>
            <Link to="/home">
              <img className={classes.logo} src={mobileLogo} alt="logo" />
            </Link>

            <div>
              <Button
                color="inherit"
                classes={{ root: classes.menuItem, label: classes.menuLabel }}
                component={Link}
                to={"/"}
              >
                Home
              </Button>
              <Button
                color="inherit"
                classes={{ root: classes.menuItem, label: classes.menuLabel }}
                component={Link}
                to={"/contact-us"}
              >
                Contact
              </Button>

              {/* {props.match.path === "/" ? (
                <Button
                  color="inherit"
                  classes={{ root: classes.menuItem, label: classes.menuLabel }}
                  onClick={() => handleAbout()}
                >
                  About us
                </Button>
              ) : (
                <Button
                  color="inherit"
                  classes={{ root: classes.menuItem, label: classes.menuLabel }}
                  component={Link}
                  to={"/home?search=aboutus"}
                >
                  About us
                </Button>
              )} */}

              <IconButton
                className={classes.menuItem}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon className={classes.drawerIcon} />
              </IconButton>
            </div>
          </div>
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default Header;
