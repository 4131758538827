import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default  createSvgIcon(
	<g transform="translate(0 0)">
		<circle opacity="0.6" cx="20.3" cy="19.8" r="1.5"/>
		<circle opacity="0.6" cx="20.1" cy="20" r="1.5"/>
		<circle opacity="0.6" cx="20.5" cy="20" r="1.5"/>
		<path opacity="0.83" d="M13.8,5.8C10.1,1.1,3.7,3.4,2.3,9.3c-0.2,1-0.3,2.1-0.2,3.2c0.4,5.8,4.2,7.6,8.9,8.6
		c5.2,1.1,10.1-3.6,10.3-8.5C21.3,5.2,15.5,7.8,13.8,5.8z M11.4,18.1c-3.4,0-6.1-2.7-6.1-6.1S8.1,6,11.4,6c3.4,0,6.1,2.7,6.1,6.1
		C17.5,15.4,14.8,18.1,11.4,18.1C11.4,18.1,11.4,18.1,11.4,18.1z"/>
		<g transform="translate(1.516 1.065)" opacity="0.55">
			<path d="M16.1,3.5C14,1.6,9.3,0.7,5.4,4.1c-5.9,5.9-6,6.5,0.5,13c2.2,2.2,7.2,2.1,9.5-0.2
			C18.9,13.5,19.6,6.6,16.1,3.5z M9.9,17.1c-3.4,0-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1S16,7.6,16,11C16,14.4,13.3,17.1,9.9,17.1
			L9.9,17.1z"/>
		</g>
		<path opacity="0.6" d="M10.3,2.5c-3.7,0-6.7,5.9-7,11c0,5.7,3.3,8,8.1,8s8.2-4.1,8.2-9S15.2,2.5,10.3,2.5z M11.4,18.1
		c-3.4,0-6.1-2.7-6.1-6.1S8.1,6,11.4,6s6.1,2.7,6.1,6.1C17.5,15.4,14.8,18.1,11.4,18.1C11.4,18.1,11.4,18.1,11.4,18.1L11.4,18.1z"/>
	</g>,
    'QuantumCloud',
);