import { Radio } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import HexagonIcon from "../icons/Hexagon";

function CustomRadio(props) {
    const useStyles = makeStyles({
        root: {
            width: "8vh",
            height: "8vh",
            marginTop: "2vh",
            '&:first-child': {
                marginLeft: 0
            },
            '& .MuiSvgIcon-root': {
                fill: "unset",
                width: "6vh",
                height: "6vh",
            },
            '& input': {
                zIndex: 10,
            },
            '& .hexagonIcon': {
                stroke:"#FDC300",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100% !important",
                height: "100% !important",
                strokeWidth: "0.4",
                fill:"none",
                '&.checked': {
                    fill:"#FDC300",
                    '@media screen and (max-device-width: 800px)': {
                        fill:"none"
                    },
                    '@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)': {
                        fill:"none"
                    }
                }
            },
            '&:hover': {
                '& .hexagonIcon': {
                    stroke:"#dba700",
                    strokeWidth: "0.6",
                    fill:"rgba(253,195,0,0.29)"
                }
            }
        },
        icon: {
            width: "5vh",
            height: "5vh",
            justifyContent: "center",
            alignContent: "center",
            fill: "#fff !important",
            display: "grid"
        },
        strokeIcon: {
            width: "5vh",
            height: "5vh",
            justifyContent: "center",
            alignContent: "center",
            // fill: "none !important",
            fill:"white",
            stroke: "#fff !important",
            display: "grid"
        },
        checkedIcon: {
            fill: "#000 !important",
            '@media screen and (max-device-width: 800px)': {
                fill: "#dba700 !important"
            },
            '@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)': {
                fill: "#dba700 !important"
            }
        },
        strokeCheckedIcon: {
            stroke: "#000 !important",
            fill:"#000",
            '@media screen and (max-device-width: 800px)': {
                stroke: "#dba700 !important"
            },
            '@media screen and (min-device-width:801px) and (max-device-width:1024px) and (orientation:portrait)': {
                stroke: "#dba700 !important"
            }
        },
        lastRadio: {
            width: "5vh",
            height: "5vh",
            '& .MuiSvgIcon-root': {
                width: "4vh",
                height: "4vh",
            },
        }
    }, {index: 1});
    const {className = "", disabled = false, wave = false, value, lastRadio, icon} = props;
    const classes = useStyles();

    return (
        <Radio
            className={`${lastRadio ? clsx(classes.lastRadio, classes.root) : classes.root} ${className}`}
            disableRipple
            color="default"
            disabled={disabled}
            value={value}
            checkedIcon={
                <span className={wave ? clsx(classes.strokeIcon, classes.strokeCheckedIcon) : clsx(classes.icon, classes.checkedIcon)}>
                    <HexagonIcon className="hexagonIcon checked"/>
                    {icon}
                </span>
            }
            icon={
                <span className={wave ? classes.strokeIcon : classes.icon}>
                    <HexagonIcon className="hexagonIcon"/>
                    {icon}
                </span>
            }
        />
    );
}
export default CustomRadio;