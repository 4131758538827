import * as React from "react";
import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.54 181.51">
    <defs></defs>
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <path
            id="d"
            class="f"
            d="M90.77,0C40.65,0,0,40.62,0,90.74c0,50.12,40.62,90.76,90.74,90.77,16.22,0,32.15-4.34,46.12-12.59-.01-.02-.03-.04-.05-.06-9.76-13.13-29.59-26.54-45.92-26.72-26.05-.14-47.92-19.66-51.02-45.52-.24-2.01-.25-4.03-.37-6.05,.03-28.43,23.1-51.45,51.53-51.42,25.9,.03,47.75,19.3,51.01,45,.65,5.49,.57,11.09,1.63,16.49,2.38,12.13,15.5,28.39,25.54,35.73,25.2-43.32,10.5-98.87-32.82-124.06C122.55,4.24,106.8,0,90.77,0Z"
          />
          <path
            id="e"
            class="f"
            d="M169.04,136.2c-7.83,13.43-18.97,24.64-32.36,32.56,0,0,17.37,19.61,34.72,2.26,17.35-17.35-2.36-34.82-2.36-34.82Z"
          />
        </g>
      </g>
    </g>
  </svg>,
  "QuantumHub"
);
