import { createStyles, makeStyles } from '@material-ui/core';

const GlobalStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: "calc(var(--vh, 1vh) * 100)",
            overflowY: "auto",
            backgroundColor: "#171A1D",
            alignItems: "center",
            "@media screen and (max-device-width: 1024px)": {
                height: "calc(var(--vh, 1vh) * 80)",
              },
        },
        flexColumn: {
            display: "flex",
            flexDirection: "column"
        },
        flexRow: {
            display: "flex",
            flexDirection: "row"
        },
        noSelect: {
            WebkitTouchCallout: "none",
            WebkitUserSelect: "none",
            KhtmlUserSelect: "none",
            MozUserSelect: "none",
            MsUserSelect: "none",
            userSelect: "none"
        }
    })
    , {index: 1});


export default GlobalStyles;
