import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// var x = window.getElementsByTagName("iframe");
// console.log(x)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// function ready(fn) {
//     if (document.readyState !== 'loading') {
//         fn();
//     } else {
//         document.addEventListener('DOMContentLoaded', fn);
//     }
// }

// ready(() => {
//     let vh = window.innerHeight * 0.01;
//     const items = document.getElementById('root');

//     items.style.setProperty('--vh', `${vh}px`);

//     window.addEventListener('resize', () => {
//         let vh = window.innerHeight * 0.01;
//         const items = document.getElementById('root');
//         items.style.setProperty('--vh', `${vh}px`);
//     });
// })